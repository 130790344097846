import React from "react";

export default ({ data, summary }) => {
  const highlightTmpl = data.map(block => {
    return (
      <div
        className="features-list"
        style={{
          background: `url(${block.image.url})`,
          backgroundSize: "auto",
          backgroundPosition: "0px 50%",
          backgroundRepeat: "no-repeat"
        }}
      >
        {block.title}
        <br />
      </div>
    );
  });
  return (
    <React.Fragment>
      <div className="white-section centered reducedtop">
        <div className="container left">
          {summary &&
            summary.title && <h2 className="h2 fixedwidth">{summary.title}</h2>}
          <div className="sm-spacing" />
          {summary &&
            summary.description && (
              <React.Fragment>
                <p className="p fixedwidth">{summary.description}</p>
                <div className="md-spacing" />
                <div className="_2cols-box" />
              </React.Fragment>
            )}

          {highlightTmpl}
          <div className="sm-spacing _30" />
        </div>
        <div className="nugit-list-landing right" />
      </div>
    </React.Fragment>
  );
};
