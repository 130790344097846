import React from "react";

export default ({ data }) => {
  let { video } = data;
  return (
    <React.Fragment>
      {data && <div
        key={video.providerUid}
        className="w-embed w-iframe video-explanation"
        id={video.providerUid}
      >
        <iframe id="embedvideo" src={video.url} frameBorder="0" />
      </div>
      }
    </React.Fragment>
  );
};
