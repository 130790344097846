import React from "react";

export default ({ data: { logos } }) => {
  return (
    <section className="logo-container">
      <div className="container product">
        {logos.map(
          (logo, index) =>
            logo && (
              <div key={index} className="client-logo">
                <img src={logo.url} alt={logo.alt}/>
              </div>
            )
        )}
      </div>
    </section>
  );
};
