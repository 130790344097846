import React from "react";
import ReactMarkdown from "react-markdown";

export default ({ data: testimonial }) => {        
    return (
        <div className="content-center">
            {testimonial.header && <h2 className="h2">{testimonial.header}</h2>}
            <div className="sm-spacing" />
            <p>
                <ReactMarkdown source={testimonial.text} escapeHtml={false} />
            </p>
            <div className="sm-spacing" />
            <div className="flex-container center">
                <img
                    src={testimonial.userAvatar && testimonial.userAvatar.url}
                    alt={testimonial.userAvatar && testimonial.userAvatar.alt}
                    className="customer-avatar"
                />
                <div className="name-customer">{testimonial.user}</div>
                {testimonial.userRole && <p className="small-copy"> - {testimonial.userRole}</p>}
            </div>
        </div>
    );
};
