import React from "react";
import { graphql } from 'gatsby'
import Top from "../components/landing-page/top";
import MainSection from "../components/landing-page/mainSection";
import VimeoLink from "../components/vimeo-link";
import WhatIsNugit from "../components/landing-page/whatIsNugit";
import { renderTestimonialSection } from "../components/home/testimonialSection";
import WhyNugit from "../components/landing-page/whyNugit";
import { HelmetDatoCms } from "gatsby-source-datocms";
import ClientLogo from "../components/client-logo";
import { getCurrentYear } from "../utils/common";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsLandingPage2: {
      seoMetaTags,
      title,
      companyLogo,
      companyHomepage,
      clientLogo,
      hubspotFormTitle,
      hubspotFormId,
      mainSection,
      secondSection,
      thirdSection,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  const secondSectionData = secondSection[0];
  const testimonialSectionData = thirdSection.filter(
    block => block.model && block.model.apiKey === "testimonial_detail"
  );
  const testimonialSectionTmpl = renderTestimonialSection(
    testimonialSectionData
  );

  const featureSummary = thirdSection.filter(
    block => block.model && block.model.apiKey === "title"
  )[0];

  const featureData = thirdSection.filter(
    block => block.model && block.model.apiKey === "feature"
  );
  
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <Top
        title={title}
        companyLogo={companyLogo}
        companyHomepage={companyHomepage}
      />
      <MainSection
        data={mainSection}
        hubspotFormId={hubspotFormId}
        hubspotFormTitle={hubspotFormTitle}
      />
      <ClientLogo data={Object.assign({ logos: clientLogo })} />
      <WhatIsNugit data={secondSectionData} />
      <WhyNugit data={featureData} summary={featureSummary} />
      {testimonialSectionTmpl}

      <div className="tint-container">
        <div className="flex-container center">
          <div id="closevid" className="close-popup" />
          <VimeoLink data={secondSectionData} />
        </div>
      </div>
      <section id="demo" className="white-section centered">
        <div className="container">
          <h2 className="h2">Start Using Nugit today</h2>
          <div className="sm-spacing _30" />
          <a href="#booking-form" className="btn w-button tracking-demo">
            Request Demo
          </a>
        </div>
      </section>
      <footer className="white-section footer-landing">
        <div className="container">
          <div className="copyright">
            <div className="made-in">© {getCurrentYear()} Nugit Ltd Pte.</div>
          </div>
        </div>
      </footer>
    </Layout>
  );
};

export const query = graphql`
  query LandingPage2Query($slug: String!) {
    datoCmsLandingPage2(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
      title
      companyLogo {
        url
      }
      companyHomepage
      clientLogo {
        alt
        url
      }
      hubspotFormTitle
      hubspotFormId
      mainSection {
        ... on DatoCmsTitle {
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsHighlight {
          highlightDetail
          model {
            apiKey
          }
        }
        ... on DatoCmsFeatureVideo {
          video {
            url
          }
          fallbackImage {
            url
          }
          model {
            apiKey
          }
        }
      }
      secondSection {
        title
        subtitle
        video {
          url
          providerUid
        }
        thumbnail {
          url
        }
      }
      thirdSection {
        ... on DatoCmsTitle {
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          image {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeature {
          title
          image {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTestimonialDetail {
          id
          text
          user
          userRole
          userAvatar {
            url
          }
          model {
            apiKey
          }
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
