import React from "react";
import Testimonial from "../common/testimonial";

export function renderTestimonialSection(data) {
  return (
    <React.Fragment>
      <section className="white-section gray">
        <div className="container">
          <div data-delay="10000" data-animation="slide" data-autoplay="1" data-duration="2000" data-infinite="1" className="testimonial-slider w-slider">
            <div className="w-slider-mask">
              {data.map(item => (
                <div className="w-slide">
                  <Testimonial data={item} key={item.id}></Testimonial>
                </div>
              ))}
            </div>
            <div className="hide w-slider-arrow-left">
              <div className="w-icon-slider-left"></div>
            </div>
            <div className="hide w-slider-arrow-right">
              <div className="w-icon-slider-right"></div>
            </div>
            <div className="slider-nav-out w-slider-nav w-slider-nav-invert w-round"></div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
