import React from 'react'
import ReactMarkdown from "react-markdown";

export default ({ data }) => {
    return (
        <React.Fragment>
            <div className="white-section centered">
                <div className="container">
                    <h2 className="h2">{data.title}<br /></h2>
                    <div className="sm-spacing" />
                    <p className="p center-align">
                        <ReactMarkdown source={data.subtitle} escapeHtml={false} />
                    </p>
                    <div className="md-spacing" />
                    <div className="video-container">
                        <img
                            src={data.thumbnail.url}
                            alt="explain video"
                            data-video-playback={data.video.providerUid}
                            className="video-thumb-home"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};